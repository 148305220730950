<template>
  <div class="evaluation_report" :style="{ opacity: opacity }">
    <navigationBar v-if="false" :showBack="showBack" :back="back">{{
      name
    }}</navigationBar>
    <reportItem ref="reportItem" v-if="false"></reportItem>
    <van-popup
      v-model="showScore"
      v-if="false"
      :close-on-click-overlay="clickFalg"
      ><score
        @handelClose="handelClose"
        @getEvaluationReport="getEvaluationReport"
        :code="code"
      ></score>
    </van-popup>

    <div class="one detail-body-first" :style="{ height: pageHeight + 'px' }">
      <img class="logo" v-if="false" src="../assets/images/cp_logo.png" alt="" />
      <div class="info">
        <div class="title">{{ info.evaluationName }}报告</div>
        <div class="item_info">
          <div class="left_box">姓名：</div>
          <div class="right_box">{{ info.realName }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">性别：</div>
          <div class="right_box">{{ info.sex }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">身份证号：</div>
          <div class="right_box">{{ info.idCard }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">手机号：</div>
          <div class="right_box">{{ info.phoneNum }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">测评机构：</div>
          <div class="right_box">{{ info.institutionName }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">报告编号：</div>
          <div class="right_box">{{ info.certificateNumber }}</div>
        </div>
        <div class="item_info">
          <div class="left_box">测评时间：</div>
          <div class="right_box">{{ info.createDateStr }}</div>
        </div>
      </div>
      <div class="tips">
        测评模型、测评报告、平台技术等均已获取知识产权保护。
      </div>
    </div>
    <div
      class="page"
      :style="{ height: pageHeight + 'px' }"
      v-for="(item, index) in pageList"
      :key="index"
    >
      <!-- 页头 -->
      <div class="title_box detail-body-first">
        <div class="left_box"></div>
        <div class="right_box">
          <div class="num">报告编号：{{ info.certificateNumber }}</div>
        </div>
      </div>

      <!-- 页尾 -->
      <div class="footer_box">
        <div class="left_box">
          <img class="footer1" src="../assets/images/footer1.png" alt="" />
          测评机构：启创未来（北京）科技有限公司
        </div>
        <div class="right_box">
          {{ index >= 9 ? index + 1 : `0${index + 1}` }}
        </div>
      </div>
    </div>
    <div class="two">
      <div class="item" v-for="(item, index1) of tabInfos" :key="index1">
        <div class="big_title_box detail-item">
          <div class="big_title_box_wrap">
            <div class="big_title">{{ item.tabValue }}</div>
          </div>
        </div>
        <div
          class="text"
          v-for="(children, index) of item.dataShows"
          :key="index"
        >
          <!-- 图标-表格 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 6"
            style="margin: 0.2rem 0"
            class="detail-item"
          >
            <div
              class="row"
              v-for="(grandson, index) of children.data.chartData.data"
              :key="index"
            >
              <div
                v-for="(grandson1, index1) of grandson.lineDatas"
                :key="index1"
              >
                {{ grandson1 }}
              </div>
            </div>
          </div>
          <!-- 文字描述 -->
          <div v-if="children.dataType == 2" class="content_text">
            <p
              class="detail-item"
              v-for="(grandson, index) of children.data"
              :key="index"
              style="
                background-color: #f5f9ff;
                color: #333333;
                padding: 0 0.16rem 0 0.16rem;
                text-indent: 2em;
                text-align: justify;
                font-size: 0.26rem;
              "
            >
              {{ grandson }}
            </p>
          </div>
          <!-- 标题描述 -->
          <div v-if="children.dataType == 3">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                style="font-size: 0.32rem; font-weight: 600"
                class="detail-item"
                v-if="grandson.title"
              >
                {{ grandson }}
              </h4>
            </div>
          </div>
          <!-- 标题文字表述 -->
          <div v-if="children.dataType == 4" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.28rem;
                  color: #005fe7;
                  font-weight: 600;
                  padding: 2px 0 10px 0;
                  background-color: rgb(245, 249, 255);
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                  font-size: 0.26rem;
                "
              >
                {{ i }}
              </p>
            </div>
          </div>
          <!-- 标题文字表述 -->
          <div v-if="children.dataType == 41" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.28rem;
                  color: #005fe7;
                  font-weight: 600;
                  padding: 2px 0 10px 0;
                  background-color: rgb(245, 249, 255);
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                "
              >
                <span
                  style="
                    width: 0.14rem;
                    height: 0.14rem;
                    display: inline-block;
                    margin-right: 0.1rem;
                    background: #999999;
                    border-radius: 50%;
                    line-height: 0.2rem;
                  "
                ></span
                >{{ i }}
              </p>
            </div>
          </div>
          <!-- 图片 -->
          <div v-if="children.dataType == 5">
            <div
              v-for="(grandson, index) of children.data"
              :key="index"
              v-html="grandson"
              class="detail-item image_box"
            ></div>
          </div>
          <!-- 三级标题带内容 -->
          <div v-if="children.dataType == 7" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #005fe7;
                  font-weight: 500;
                  padding: 2px 0 10px 0;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  font-size: 0.26rem;
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                "
              >
                {{ i }}
              </p>
            </div>
          </div>
          <!-- 四级标题带内容 -->
          <div v-if="children.dataType == 7" class="content_text">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #333333;
                  font-weight: 600;
                  padding: 2px 0 10px 0;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                  font-size: 0.26rem;
                "
              >
                {{ i }}
              </p>
            </div>
          </div>
          <div v-if="children.dataType == 71">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.28rem;
                  color: #005fe7;
                  font-weight: 600;
                  padding: 2px 0 10px 0;
                  background-color: rgb(245, 249, 255);
                "
                v-if="grandson.title"
              >
                {{ grandson.title }}
              </h4>
              <p
                class="detail-item"
                v-for="(i, index) of grandson.descriptions"
                :key="index"
                style="
                  background-color: #f5f9ff;
                  color: #333333;
                  padding: 0 0.16rem 0 0.16rem;
                  text-indent: 2em;
                  text-align: justify;
                "
              >
                <span
                  style="
                    width: 0.14rem;
                    height: 0.14rem;
                    display: inline-block;
                    margin-right: 0.1rem;
                    background: #999999;
                    border-radius: 50%;
                    line-height: 0.2rem;
                  "
                ></span
                >{{ i }}
              </p>
            </div>
          </div>
          <!-- 加粗文本 -->
          <div v-if="children.dataType == 8">
            <div v-for="(grandson, index) of children.data" :key="index">
              <h4
                class="detail-item"
                style="
                  font-size: 0.26rem;
                  color: #333333;
                  font-weight: 600;
                  padding: 2px 0 10px 0;
                  background-color: rgb(245, 249, 255);
                  text-indent: 2em;
                "
              >
                {{ grandson }}
              </h4>
            </div>
          </div>
          <!-- 图标-雷达图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 1"
            class="detail-item"
          >
            <div
              :id="'echarts1' + index1"
              :style="{ width: '100%', height: '7rem' }"
              style="margin: 0 auto"
            ></div>
          </div>
          <!-- 图标-柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 2"
            class="detail-item"
          >
            <div
              :id="'echarts2' + index1"
              :style="{ width: '100%', height: '7rem' }"
              style="margin: 0 auto"
            ></div>
          </div>
          <!-- 图标-横向柱状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 3"
            class="detail-item"
          >
            <div
              :id="'echarts3' + index1"
              :style="{ width: '100%', height: '7rem' }"
              style="margin: 0 auto"
            ></div>
          </div>
          <!-- 图标-饼状图 -->
          <div
            v-if="children.dataType == 1 && children.data.chartType == 4"
            class="detail-item"
          >
            <div
              :id="'echarts4' + index1"
              :style="{ width: '100%', height: '7rem' }"
              style="margin: 0 auto"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getLoginToken, getReport} from "@/request/api";
import { mapMutations } from "vuex";
import navigationBar from "@/components/navigationBar/navigationBar";
import reportItem from "@/components/reportItem/reportItem";
import score from "@/components/score/score";

export default {
  data() {
    return {
      showBack: true,
      name: "",
      back: 0,
      showScore: false,
      clickFalg: false,
      code: 0,
      userId: 0,
      tabInfos: [],
      info: {},
      screenHeight: document.documentElement.clientHeight,
      screenWidth: document.documentElement.clientWidth,
      pageList: [],
      pageHeight: 0,
      opacity: 0,
    };
  },
  components: {
    navigationBar,
    reportItem,
    score,
  },
  created() {
    this.key = this.$route.query.token;
    this.loginUsers = JSON.parse(localStorage.getItem("loginUser"));
    if(this.loginUsers) {
      this.userId = this.loginUsers.id;
    }
    this.code = this.$route.query.code;
  },
  mounted() {
    if(this.key) {
       this.getReport(this.key)
       this.getA4Size();
    }
  },
  methods: {
    ...mapMutations(["changeLogin", "loginUser"]),
    getLoginToken(param) {
      getLoginToken(param).then((res) => {
        console.log(res);
        if(res.token) {
          this.changeLogin(res.token);
          this.loginUser(JSON.stringify(res.loginUser));
          this.getEvaluationReport(this.userId, this.code);
          this.loginUsers = JSON.parse(localStorage.getItem("loginUser"));
          this.userId = this.loginUsers.id;
          this.code = this.$route.query.code;
        }
      });
    },
    // 计算a4纸的大小
    getA4Size() {
      console.log(this.screenWidth);
      const proportion = 1060 / 750;
      this.pageHeight = parseInt(this.screenWidth * proportion);
    },
    handelClose(res) {
      this.showScore = res;
    },
    handelOpen() {
      this.showScore = true;
    },
    getReport(res) {
      getReport(res).then((res) => {
        console.log(res)
        this.userInfo = res.data;
        this.info = res.data;
        this.$emit('getUserInfo', this.userInfo)
        this.tabInfos = res.data.tabInfos;
        if (res.data.score == 0) {
          if (res.data.tabInfos.length <= 2) {
            this.handelMore();
            this.showMore = false;
            this.processingData();
          } else {
            this.showMore = true;
            this.tabInfos = res.data.tabInfos.slice(0, 2);
            this.processingData();
          }
        } else {
          this.showMore = false;
          this.tabInfos = res.data.tabInfos;
          this.processingData();
        }
        this.$nextTick(() => {
          // 处理数据
          setTimeout(() => {
            let domList = Array.from(
              document.getElementsByClassName("detail-item")
            );
            console.log(domList);
            let allArr = [];
            let oneceArr = [];
            let allArrIndex = 0;
            let allHeight = 0;
            let maxHeihg = parseInt(
              this.pageHeight -
                parseInt((147 / 1060) * this.pageHeight) -
                parseInt((72 / 1060) * this.pageHeight)
            );
            console.log(maxHeihg);
            domList.forEach((item, index) => {
              allHeight += item.clientHeight;
              if (allHeight > maxHeihg) {
                allArr[allArrIndex] = oneceArr.concat([]);
                oneceArr = [item];
                allArrIndex += 1;
                allHeight = item.clientHeight;
              } else {
                oneceArr.push(item);
              }
              if (index == domList.length - 1 && oneceArr.length > 0) {
                allArr.push(oneceArr);
              }
            });
            console.log(allArr);
            // 计算每一页的高度
            // let pageTop =
            //   document.getElementsByClassName("detail-body-first")[0]
            //     .clientHeight + 20;
            let pageTop = this.pageHeight + 20;
            this.pageList = allArr;
            let yemeiHeigh = parseInt((95 / 1060) * this.pageHeight);
            this.$nextTick(() => {
              this.pageList.forEach((item, index) => {
                // 计算出起始高度高
                let startTop = pageTop * (index + 1) + yemeiHeigh;
                let nowPageHeight = 0;
                item.forEach((children, childrenIndex) => {
                  if (childrenIndex == 0) {
                    children.style.top = startTop + "px";
                  } else {
                    nowPageHeight += item[childrenIndex - 1].clientHeight;
                    children.style.top = nowPageHeight + startTop + "px";
                  }
                });
              });
            });
            this.opacity = 1;
          }, 800);
        });
      })
    },
    // 渲染饼柱状图
    renderCakeColumn(dom, data) {
      let colorList = [
        "#FFB400",
        "#FF7800",
        "#FF0060",
        "#7800FF",
        "#00FB8D",
        "#00E4FF",
        "#1555E7",
      ];
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
          show: false,
        },
        series: [
          {
            type: "pie",

            center: ["50%", "50%"],
            radius: ["24%", "45%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "outside",
              // formatter: '{a|{b}：{d}%}\n{hr|}',
              formatter(v) {
                console.log(v);
                let text = v.name;
                return text.length < 4
                  ? text + v.data.value
                  : `${text.slice(0, 4)}\n${text.slice(4)}${v.data.value}`;
              },
              rich: {
                hr: {
                  backgroundColor: "t",
                  borderRadius: 3,
                  width: 3,
                  height: 3,
                  padding: [3, 3, 0, -12],
                },
                a: {
                  padding: [-30, 15, -20, 15],
                },
              },
            },
            labelLine: {
              normal: {
                length: 20,
                length2: 30,
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: data.seriesData,
          },
        ],
      });
    },
    // 处理各种图标数据
    processingData() {
      this.tabInfos.forEach((item, index) => {
        item.dataShows.forEach((cItem) => {
          if (cItem.dataType == 1 && cItem.data.chartType == 1) {
            this.$nextTick(() => {
              this.renderRadar("echarts1" + index, cItem.data.chartData);
            });
          } else if (cItem.dataType == 1 && cItem.data.chartType == 2) {
            this.$nextTick(() => {
              this.renderVerticalColumn(
                "echarts2" + index,
                cItem.data.chartData
              );
            });
          } else if (cItem.dataType == 1 && cItem.data.chartType == 3) {
            this.$nextTick(() => {
              this.renderTransverseColumn(
                "echarts3" + index,
                cItem.data.chartData
              );
            });
          } else if (cItem.dataType == 1 && cItem.data.chartType == 4) {
            this.$nextTick(() => {
              this.renderCakeColumn("echarts4" + index, cItem.data.chartData);
            });
          }
        });
      });
    },

    // 渲染雷达
    renderRadar(dom, data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        title: {},
        legend: {
          // data: ["预算分配（Allocated Budget）"],
          data: data.legendData,
        },
        radar: {
          // shape: 'circle',
          indicator: data.indicator,
          axisLine: {
            lineStyle: {
              color: "#005fe7",
            },
          },
          radius: 110,
        },
        series: [
          {
            name: "",
            type: "radar",
            data: data.seriesData,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            lineStyle: {
              normal: {
                color: "#005FE7",
                type: "solid",
                width: 3,
              },
              areaStyle: {
                normal: {
                  color: "rgba(6, 222, 249,0.3)",
                },

                emphasis: {
                  color: "rgba(6, 222, 249,0.5)",
                },
              },
              emphasis: {},
            },
          },
        ],
      });
    },

    // 渲染竖向柱状图
    renderVerticalColumn(dom, data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          data: data.xaxisData,
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            //柱底圆片
            name: "",
            type: "pictorialBar",
            symbolSize: [30, 10],
            symbolOffset: [0, 6],
            z: 12,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(89,211,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(23,237,194,1)",
                  },
                ]),
              },
            },
            data: data.seriesData,
          },

          //柱体
          {
            name: "",
            type: "bar",
            barWidth: 30,
            barGap: "0%",
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      //第一节下面
                      offset: 0,
                      color: "rgba(0,255,245,0.5)",
                    },
                    {
                      offset: 1,
                      color: "#43bafe",
                    },
                  ],
                },
              },
            },

            data: data.seriesData,
          },

          //柱顶圆片
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [30, 10],
            symbolOffset: [0, -6],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(89,211,255,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(23,237,194,1)",
                    },
                  ],
                  false
                ),
              },
            },
            data: data.seriesData,
          },
        ],
      });
    },

    // 渲染横向柱状图
    renderTransverseColumn(dom, data) {
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(dom));

      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "value",
          data: data.xaxisData,
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "category",
        },
        series: [
          {
            data: data.seriesData,
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "black",
                    fontSize: 14,
                  },
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style scoped lang="less">
.evaluation_report {
  position: relative;
  background-color: #f4f4f4;
}

/deep/ .van-popup {
  background-color: transparent;
  border-radius: 0.1rem;
}

.one {
  // height: 100vh;
  background-image: url("../assets/images/cp_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 20px;

  .logo {
    width: 1.94rem;
    height: 0.5rem;
    position: absolute;
    left: 0.98rem;
    top: 29px;
  }

  .info {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 117px;
    transform: translateX(-50%);

    .title {
      font-size: 0.38rem;
      font-weight: 500;
      color: #1555e7;
      text-shadow: 0.01em 0.01rem 0.01rem rgba(0, 61, 113, 0.33);
      -webkit-text-stroke: 0.01rem #ffffff;
      text-stroke: 0.01rem #ffffff;
      margin: 0 0 35px 0;
    }

    .item_info {
      display: flex;
      justify-content: center;
      margin-bottom: 18px;
      font-size: 0.3rem;
      font-weight: 400;
      color: #333333;

      .left_box {
        width: 34%;
        text-align: right;
      }
      .right_box {
        width: 60%;
        text-align: left;
      }
    }
  }

  .tips {
    width: 100%;
    font-size: 0.2rem;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.7;
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.page {
  // height: 100vh;
  background-color: #ffffff;
  padding: 14px 0 18px 0;
  position: relative;
  margin-bottom: 20px;

  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 0.01rem solid #1555e7;
    width: calc(100% - 0.47rem - 0.47rem);
    margin: 0 auto 0;

    .left_box {
      width: 1.71rem;
      height: 0.53rem;
      background-image: url("../assets/images/cp_left.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 3.39rem;
      height: 0.32rem;
      background-image: url("../assets/images/cp_right.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;

      .num {
        font-size: 0.18rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .footer_box {
    position: absolute;
    bottom: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid #1555e7;
    width: calc(100% - 0.47rem - 0.47rem);
    left: 50%;
    transform: translateX(-50%);

    .left_box {
      display: flex;
      align-items: center;
      font-size: 0.18rem;
      font-weight: 400;
      color: #1555e7;

      .footer1 {
        width: 0.35rem;
        height: 0.23rem;
        margin-right: 0.1rem;
      }

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 0.77rem;
      height: 0.34rem;
      background-image: url("../assets/images/footer2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;
      font-size: 0.22rem;
      font-weight: 400;
      color: #ffffff;
      font-size: 0.15rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.two {
  // height: 100vh;
  // background-color: #ffffff;
  // padding: 0.6rem 0.47rem 0.6rem 0.47rem;

  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 0.01rem solid #1555e7;
    margin-bottom: 0.74rem;

    .left_box {
      width: 1.71rem;
      height: 0.53rem;
      background-image: url("../assets/images/cp_left.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 3.39rem;
      height: 0.32rem;
      background-image: url("../assets/images/cp_right.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;

      .num {
        font-size: 0.18rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .big_title_box {
    padding: 26px 0 20px 0;
    display: inline-block;
    width: auto;

    .big_title_box_wrap {
      border: 0.01rem solid #1555e7;
      border-radius: 0.08rem;
    }

    .big_title {
      font-size: 0.3rem;
      font-weight: 600;
      color: #005fe7;
      background: #e7f1ff;
      border-radius: 0.08rem;
      padding: 0.14rem 0.24rem;
      position: relative;
      left: -0.09rem;
      top: -0.11rem;
    }
  }

  .content {
    padding: 0.16rem;
    background-color: #f5f9ff;
    font-size: 0.17rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.4rem;
  }

  .footer_box {
    position: absolute;
    bottom: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.01rem solid #1555e7;
    width: calc(100% - 0.47rem - 0.47rem);

    .left_box {
      display: flex;
      align-items: center;
      font-size: 0.18rem;
      font-weight: 400;
      color: #1555e7;

      .footer1 {
        width: 0.35rem;
        height: 0.23rem;
        margin-right: 0.1rem;
      }

      .logo1 {
        width: 0.81rem;
        height: 0.21rem;
        margin-top: 0.12rem;
      }
    }

    .right_box {
      width: 0.77rem;
      height: 0.34rem;
      background-image: url("../assets/images/footer2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0.1rem;
      font-size: 0.22rem;
      font-weight: 400;
      color: #ffffff;
      font-size: 0.15rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.item {
  // background-color: #ffffff;
  // border-radius: 0.12rem;
  // margin-bottom: 0.2rem;

  &:last-child {
    margin: 0;
  }

  .title {
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 600;
    color: #ffffff;
    padding: 0.2rem 0.3rem;
    background: #0067b0;
    border-radius: 0.45rem;
    margin-bottom: 0.3rem;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    &:after {
      content: "";
      width: 0.4rem;
      height: 0.58rem;
      background-image: url("../assets/images/title_left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: -0.6rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      content: "";
      width: 0.4rem;
      height: 0.58rem;
      background-image: url("../assets/images/title_right.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -0.6rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .text {
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #333333;
    line-height: 0.46rem;

    .row {
      display: flex;
      border-top: 0.01rem solid #cccccc;
      border-left: 0.01rem solid #cccccc;
      border-right: 0.01rem solid #cccccc;

      &:last-child {
        border-bottom: 0.01rem solid #cccccc;
      }

      &:first-child {
        div {
          background-color: #e7f1ff;
          color: #1555e7;
        }
      }

      div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 0.01rem solid #cccccc;
      }

      div:last-child {
        padding: 0.1rem;
        border-right: none;
      }
    }
  }
}

.more {
  font-size: 0.28rem;
  font-family: "PingFangSC-Regular";
  font-weight: 600;
  color: #ffffff;
  padding: 0.2rem 0.3rem;
  background: #0067b0;
  border-radius: 0.45rem;
  margin: 0.4rem 0 0 0;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.detail-item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 0.47rem - 0.47rem);
}

/deep/ .image_box img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

p {
  line-height: 0.4rem;
}

/deep/ .content_text p:first-child {
  padding-top: 0.16rem !important;
}

/deep/ .content_text p:last-child {
  padding-bottom: 0.16rem !important;
}
</style>
